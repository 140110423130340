import { useStaticQuery, graphql } from "gatsby"
export const useCompareInternetPlans = () => {
  const result = useStaticQuery(
    graphql`
      query useCompareInternetPlans {
        allCompareInternetDataJson(sort: { fields: priority }) {
          edges {
            node {
              daily
              desc
              forHome
              id
              img
              label
              link
              name
              price
              priority
              type
            }
          }
        }
      }
    `
  )

  return result.allCompareInternetDataJson.edges
}
