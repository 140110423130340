import React from "react"
import styled from "styled-components"

const Button = styled.div`
  background: #1616b5;
  color: white;
  margin: 0.21rem;
  padding: 0.8rem 1.5rem;
  border-radius: 10px;
  flex: 1;
  text-align: center;
  max-width: 350px;
`

export const InternetCompareButton = ({ children, handleClick }) => {
  return <Button onClick={handleClick}>{children}</Button>
}
