import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
  align-items: center;
  margin: 0rem auto;
`

export const InternetContainer = ({ children }) => {
  return <Container>{children}</Container>
}
