import { OutboundLink } from "gatsby-plugin-gtag"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useCompareInternetPlans } from "../../../hooks/useCompareInternetPlans"
import OperatorImage from "../../images/OperatorImage"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: lightgray;
  margin: 1rem;
  flex: 1;
  max-width: 400px;
  border-radius: 10px;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
`

const Number = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  background: blue;
  color: white;
  padding: 0.2rem;
  margin-top: 2rem;
`

const InfoHeader = styled.h5`
  font-size: 1.1rem;
  color: black;
  text-align: center;
`

const Text = styled.p`
  font-weight: 800;
  margin: 0rem;
  font-size: 1.5rem;
`

const Button = styled.button`
  padding: 1rem 2rem;
  margin: 1rem auto;
  border: none;
  background: #f66000;
  color: white;
  font-size: 1.2rem;
`

const Desc = styled.p`
  margin: 1rem 2rem;
  padding: 0.5rem;
  font-weight: 400;
  text-align: left;
  flex: 1;
`

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const FilterButton = styled.button`
  border: none;
  margin: 1rem;
  padding: 1rem 2rem;
  font-weight: 400;
  border-radius: 10px;
  color: white;
  background: #1616b5;
  font-size: 1.2rem;
`
const FilterHeader = styled.h3`
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
`

const Price = styled.p`
  font-size: 1.7rem;
  text-align: center;
  margin: 0rem 4rem;
`
const Link = styled.a`
  margin: 0rem auto;
`

export const CompareInternetResult = ({ toHome, daily, category }) => {
  const [useFilter, setUseFilter] = useState(true)
  const [filter, setFilter] = useState(null)
  const [plans, setPlans] = useState(useCompareInternetPlans())
  useEffect(() => {
    if (category === "laitenetti") {
      setUseFilter(false)
      const filteredPlans = plans.filter(
        ({ node }) => node.type === "laitenetti"
      )
      setPlans(filteredPlans)
      return
    }
    if (toHome) {
      const filteredPlans = plans.filter(
        plan => plan.node.forHome === toHome && plan.node.daily === daily
      )
      setUseFilter(false)
      setPlans(filteredPlans)
      return
    }
    if (!toHome && filter !== null) {
      const filteredPlans = plans.filter(
        ({ node }) =>
          node.daily === filter &&
          node.type !== "kiintea" &&
          node.type !== "laitenetti"
      )
      setPlans(filteredPlans)
    } else {
      const filteredPlans = plans.filter(
        ({ node }) => node.type !== "kiintea" && node.type !== "laitenetti"
      )
      setPlans(filteredPlans)
    }
  }, [useFilter, filter, toHome])

  const handleButtonClick = (label, action) => {
    if (!process.env.GATSBY_MY_ENVIRONMENT)
      typeof window !== "undefined" &&
        window.gtag("event", `Netti ${action}`, {
          event_category: "nettiliittymaVertailuAction",
          event_label: label,
        })
  }

  const handleFilter = (filter, filterText, action) => {
    if (!process.env.GATSBY_MY_ENVIRONMENT)
      typeof window !== "undefined" &&
        window.gtag("event", `Netti ${action}`, {
          event_category: "nettiliittymaVertailuFilter",
          event_label: filterText,
        })
    setUseFilter(false)
    setFilter(filter)
  }

  // TODO: Haetaan data Jsonista
  // Filtteröidään haussa liittymät jotka ovat kotiin
  // Mapataan näistä resultcontainerit
  // Tämä componentti saa propsina onko Kotiin, matkalle, mokkulaan tai tablettiin

  const resultCards = plans.map(({ node }, i) => {
    return (
      <ResultContainer>
        <HeaderContainer>
          <Number>{i + 1}</Number>
          <Text>{node.name}</Text>
          <OperatorImage imgName={node.img} />
        </HeaderContainer>
        <Desc
          dangerouslySetInnerHTML={{
            __html: node.desc.replaceAll(".", ".<Br><Br>"),
          }}
        ></Desc>
        <Price>Hinta alkaen {node.price}€/kk</Price>
        <Link
          target="_blank"
          rel="nofollow"
          href={`${node.link}&epi=nettiliittymavertailu-${category}-${node.label}`}
        >
          <Button onClick={() => handleButtonClick(node.label, category)}>
            Tarkista saatavuus
          </Button>
        </Link>
      </ResultContainer>
    )
  })

  return (
    <>
      {useFilter && (
        <>
          <FilterHeader>Kuinka usein käytät nettiä?</FilterHeader>
          <FilterContainer>
            <FilterButton
              onClick={() => handleFilter(true, "päivittäin", category)}
            >
              Päivittäin
            </FilterButton>
            <FilterButton
              onClick={() => handleFilter(false, "silloinTällöin", category)}
            >
              Silloin tällöin
            </FilterButton>
            <FilterButton
              onClick={() => handleFilter(null, "kaikki", category)}
            >
              Näytä kaikki
            </FilterButton>
          </FilterContainer>
        </>
      )}
      {!useFilter && (
        <>
          <InfoHeader>Sinulla on seuraavat vaihtoehdot</InfoHeader>
          <Container>{resultCards}</Container>
        </>
      )}
    </>
  )
}
