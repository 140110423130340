import React, { useState } from "react"
import { Operators } from "../compareApps/Operators"
import Header4 from "../layout/textFormatingComponents/Header4"
import { InternetCompareButton } from "./InternetCompareButton"
import { InternetContainer } from "./InternetContainer"
import styled from "styled-components"
import { CompareInternetResult } from "./results/CompareInternetResult"

const Container = styled.div`
  margin: 0rem auto;
`

const Text = styled.div`
  margin: 1rem;
  font-weight: 700;
  text-align: center;
`

const ExtraContainer = styled.div`
  background: lightgray;
  margin-bottom: 5rem;
  padding: 0.5rem;
  margin-top: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0rem;
  padding: 0rem;
`

export const CompareInternetPlans = () => {
  const [selection, setSelection] = useState("start")

  const handleSelection = selection => {
    if (!process.env.GATSBY_MY_ENVIRONMENT)
      typeof window !== "undefined" &&
        window.gtag("event", `NettiliittymäVertailu`, {
          event_category: "nettiliittymaVertailuCategory",
          event_label: selection,
        })
    setSelection(selection)
  }
  return (
    <Container>
      <div>
        <Header4>
          {selection === "start"
            ? "Kerro mihin tarvitset nettiä."
            : `Netti ${selection}`}
        </Header4>
        {selection === "start" && (
          <>
            <InternetContainer>
              <InternetCompareButton
                handleClick={() => handleSelection("kotiin")}
              >
                Kotiin
              </InternetCompareButton>
              <InternetCompareButton
                handleClick={() => handleSelection("matkalle")}
              >
                Matkalle
              </InternetCompareButton>
              <InternetCompareButton
                handleClick={() => handleSelection("tablettiin")}
              >
                Tablettiin
              </InternetCompareButton>
              <InternetCompareButton
                handleClick={() => handleSelection("mokkulaan")}
              >
                Mokkulaan
              </InternetCompareButton>
            </InternetContainer>
            <ExtraContainer>
              <Text>
                Tarvitsetko nettiliittymän valvontakameraan, riistakameraan tai
                esimerkiksi koiran tutkapantaan?
              </Text>
              <TextContainer>
                <InternetCompareButton
                  handleClick={() => handleSelection("laitenetti")}
                >
                  Katso laitenetit
                </InternetCompareButton>
              </TextContainer>
            </ExtraContainer>
          </>
        )}
        {selection === "kotiin" && (
          <CompareInternetResult
            toHome={true}
            daily={true}
            category={selection}
          />
        )}
        {selection === "matkalle" && (
          <CompareInternetResult
            toHome={false}
            daily={true || false}
            category={selection}
          />
        )}
        {selection === "tablettiin" && (
          <CompareInternetResult
            toHome={false}
            daily={true || false}
            category={selection}
          />
        )}
        {selection === "mokkulaan" && (
          <CompareInternetResult
            toHome={false}
            daily={true || false}
            category={selection}
          />
        )}
        {selection === "laitenetti" && (
          <CompareInternetResult category={selection} />
        )}
      </div>

      <Operators />
    </Container>
  )
}
