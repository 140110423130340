import React from "react"
import Container from "../components/layout/textFormatingComponents/Container"
import { graphql } from "gatsby"
import InternetPlanCard from "../components/resultComponents/InternetPlanCard"
import GlobalStyles from "../components/layout/GlobalStyles"
import Header1 from "../components/layout/textFormatingComponents/Header1"
import Header2 from "../components/layout/textFormatingComponents/Header2"
import Header3 from "../components/layout/textFormatingComponents/Header3"
import Header4 from "../components/layout/textFormatingComponents/Header4"
import TextArea from "../components/layout/textFormatingComponents/TextArea"
import MetaDetails from "../components/seoComponents/MetaDetails"
import RowContainer from "../components/layout/textFormatingComponents/RowContainer"
import Button from "../components/layout/Button"
import { InternetContainer } from "../components/internetCompare/InternetContainer"
import { InternetCompareButton } from "../components/internetCompare/InternetCompareButton"
import { Operators } from "../components/compareApps/Operators"
import { CompareInternetPlans } from "../components/internetCompare/CompareInternetPlans"

const FrontPage = ({ data }) => {
  const plansHook = data.allInternetPlansJson.nodes
  const plans = plansHook.map(plan => {
    return (
      <InternetPlanCard
        key={plan.id}
        img={plan.img}
        name={plan.name}
        price={plan.price}
        internetSpeed={plan.internetSpeed}
        url={plan.url}
        openFee={plan.openFee}
        benefits={plan.benefits}
        pageName={"nettiliittyma"}
      />
    )
  })

  return (
    <GlobalStyles>
      <MetaDetails
        title="Halvin nettiliittymä kotiin,töihin tai matkalle - Vertaa nettiliittymät! "
        desc="Halvin nettiliittymä löytyy nettiliittymä vertailun avulla. Mobiililaajakaista tai kiinteä nettiyhteys kotiin tarjous. Valitse Elisa, Dna, Telia tai Moi!"
      />
      <Container>
        <Header1>
          Mikä on halvin nettiliittymä kotiin, matkalle tai tablettiin?
        </Header1>
        <CompareInternetPlans />
        <TextArea>
          Kotiin tarvitaan nykyään jo lähes aina jonkinlainen nettiliittymä.
          Valinnan varaa kuitenkin riittää melko reippaasti, joten ongelmaksi
          saattaa muodostua mikä nettiliittymä olisi juuri minulle se paras.
          Tällä sivulla koitamme löytää sinulle oikean liittymän, jolla arjen
          perustarpee hoituvat edullisesti sekä nopeasti.
        </TextArea>
        <Header3>Tutustu parhaimpiin nettiliittymiin alapuolelta:</Header3>
        {plans}
        <RowContainer title={"Myös nämä saattavat kiinnostaa sunua:"}>
          <Button link={"/mokkula/"} title={"Mokkulat"} />
          <Button link={"/mobiililaajakaista/"} title={"Mobiililaajakaista"} />
        </RowContainer>
        <Header2>Vinkit parhaan nettiliittymän valitsemiseen kotiin</Header2>
        <TextArea>
          <b>
            Nettiliittymän vertailu kotiin on yksi nykykotitalouden tärkeimmistä
            sopimuksista sähkösopimuksen ja kotivakuutuksen ohella
          </b>
          . Nämä kolme sopimusta ovat myös sellaisia, joissa kuluttaja voi itse
          päättää, minkä toimijan valitsee. Toisin on esimerkiksi vesiyhtiön
          valinnassa, joskin lähes kaikki Suomen suuret vesiyhtiöt ja
          -osuuskunnat ovat kaupunkien ja kuntien omistamia. Vaikka aikaisemmin
          myös tietoverkot ja myös suurimmat matkapuhelinverkot olivat
          julkisessa omistuksessa, nykyään kaikki Suomen teleoperaattorit ovat
          yksityisessä omistuksessa. Suurimmat näistä ovat myös pörssiyhtiöitä.
        </TextArea>
        <TextArea>
          Suomessa tämä kilpailun avaaminen ja operaattorien yksityistäminen
          kesti noin 15 vuotta, ja tämä on ollut omalta osaltaan hyvä asia myös
          kuluttajille, sillä hinnat ovat kilpailun myötä tippuneet.
          Teleoperaattoritoiminta ja nettiliittymien tarjoaminen onkin todella
          kilpailtu toimiala Suomen lisäksi muuallakin maailmassa, ja kuluttajan
          on helppo tehdä huomattavia säästöjä vertailemalla erilaisia
          nettiliittymiä kotiin. Suomessa kotiin tarkoitettuja nettiliittymiä
          tarjoavat isot teleoperaattorit, eli <b>Elisa, DNA ja Telia</b>.
          Lisäksi on muutama pienempi yritys, jotka tarjoavat palvelu-, tai
          virtuaalioperaattoreina erilaisia älypuhelimiin tarkoitettuja
          liittymiä, jotka luonnollisesti sisältävät myös datapaketin netin
          käyttöön.
        </TextArea>
        <Header3>Kiinteä laajakaista kotiin</Header3>
        <TextArea>
          Kiinteä laajakaista on monelle elinehto, ja monet eivät voi edes
          kuvitella omaa tai perheensä asuntoa ilman kiinteää laajakaistaa.
          Tavallisesti kiinteä laajakaista tunnetaan WLAN-verkosta eli
          Wi-Fi:stä, jonka olemassaolon huomaa vasta siinä vaiheessa, kun se ei
          toimi. Välillä tämä voi johtua huonosta kaapeloinnista tai ongelmasta
          tietoverkossa, mutta yleensä kyse on ongelmista palveluntarjoajan ja
          laitteen, kuten reitittimen tai mokkulan kanssa. Tällöin voi olla hyvä
          vaihtoehto tarkastella eri palveluntarjoajia. Myös jos oman kodin
          kiinteä laajakaista tuntuu liian kalliilta, kannattaa rohkeasti
          kilpailuttaa palvelu, sillä tällä tavalla on mahdollista säästää
          huomattavasti. Nettiliittymän vertailu kannattaa aloittaa
          perusasioista.
        </TextArea>
        <TextArea>
          Tärkeintä on toki tiedustella ensin, mitkä voisivat olla vaihtoehdot,
          sillä kaikki operaattorit eivät tarjoa kiinteää laajakaistaa Suomen
          joka kolkkaan. Valitettavasti erityisesti, jos asut
          haja-asutusalueella, voi olla mahdollista, että et saa
          liittymätarjouksia kaikilta eri toimijoilta, sillä joillain
          teleoperaattoreilla on tietyillä alueella luonnollinen monopoli.
          Toinen vaihtoehto on, että palvelua ei ole lainkaan saatavilla,
          jolloin tulee turvautua mobiililaajakaistaan. Toinen selvitettävä asia
          kaikissa nettiliittymävertailussa on tietysti se, kuinka paljon sille
          on käyttöä. Tämä kannattaa laskeskella suoraan edellisistä
          nettilaskuista, joista näkee helposti, mistä kaikesta palvelussa
          oikeasti maksaa, vai tuleeko sitä maksaneeksi esimerkiksi
          lisäpalveluista, joita ei edes käytä.
        </TextArea>
        <TextArea>
          Joskus voi olla, että omassa kodissa on vuosia vanha liittymä, jota ei
          vain syystä tai toisesta ole ikinä tullut vaihtaneeksi, mutta joka
          toimii todella hitaasti. Lisäksi modeemi voi olla pitkän käytön
          jälkeen siinä pisteessä, että sekin pitäisi vaihtaa. Erilaiset
          nettipalvelut käyttävät dataa eri tavalla, mikä kannattaa ottaa
          huomioon liittymävertailussa. Jos esimerkiksi nettiä käytetään
          pelkästään lähinnä uutisten lukemiseen, ei ole välttämättä järkevää
          maksaa monen sadan megan kiinteää laajakaistaa kotiin.
          Liittymävertailu tulee siis suhteuttaa siihen, mitä netissä tehdään.
          Voi myös olla, että kiinteää laajakaistaa ei tarvitse kotiin, jos
          hyvin toimiva WLAN-verkko on jo työpaikalla. Ota nämä asiat huomioon
          laajakaistaverkkoa hankkiessa:
          <ul>
            <li>
              Suomessa toimivat laajakaistaliittymät ovat suhteellisen edullisia
            </li>
            <li>
              Hinnat vaihtelevat noin 10 eurosta 80 euroon riippuen omista
              tarpeista
            </li>
            <li>
              Laajakaistaliittymän hankkimiseksi tulee tarkastaa, onko kodissasi
              käytössä kaapeli vai valokuituverkko
            </li>
            <li>Kysymyksiin saat apua myös suoraan teleoperaattoreilta</li>
          </ul>
        </TextArea>
        <Header3>Halvin nettiliittymä monelta eri operaattorilta</Header3>
        <TextArea>
          Telialla on useita erilaisia kiinteitä laajakaistapalveluja
          kotitalouksille, joita markkinoidaan yleisesti nimellä Telia
          Laajakaista. Tavallisen Telia Laajakaistan saa tilattua 10 megan
          nopeudesta jopa yhden gigan kaapeli- ja valokuituverkkoon.
          Valokuituverkko on Telian tarjoamista laajakaistaliittymistä kaikkein
          nopein, mutta sen saatavuuksissa voi olla eroja eri paikkakuntien
          välillä. DNA tarjoaa laajakaistaliittymiä nimellä DNA Netti, ja
          laajakaistasta on saatavilla kolme eri versiota: DNA Netti, DNA Netti
          + TV Hubi, ja DNA Netti + Matkanetti. TV Hubi-lisäpalvelu on
          tarkoitettu erityisesti TV-viihteen ystäville, kun taas matkanetti
          tarjoaa mahdollisuuden kotiliittymän lisäksi surffaamiseen myös kodin
          ulkopuolella. Elisa taas tarjoaa laajakaistapalveluja sekä Elisa
          Viihteeseen yhdistettynä että erillisenä pakettina, Saunalahti
          Laajakaistana.
        </TextArea>
        <Header4>Mokkula</Header4>
        <TextArea>
          Mokkula on käytännössä mobiilinetillä toimiva modeemi, josta kuitenkin
          käytetään hienompaa nimitystä reititin. Mokkula on se muovisen
          saippuakotelon näköinen väline, joka jää helposti pölyttymään
          kaappiin, jos ei tiedä, mitä sillä tekee. Muutama vuosi sitten
          nimittäin monien kiinteiden nettiliittymien kylkiäisiksi tarjottiin
          mokkulaa, jossa ei kuitenkaan ollut erillistä SIM-korttia yhteyden
          luomiseksi. Nykyään mokkuloita ostetaan erillisenä tuotteena, ja
          pääasiassa mokkulan etu on siis mobiiliyhteyden luominen lähes missä
          tahansa, jossa verkko on saatavilla. Suomalaiset luultavasti hankkivat
          eniten mokkuloita erityisesti kesätoimistolleen, eli mökille.
          Parhaimmillaan mokkulaa voidaan käyttää usealla eri laitteella, kuten
          tietokoneella, tabletilla tai älytelevisiossa.
        </TextArea>
        <TextArea>
          Mokkuloita tarjoavat kiinteiden nettiliittymien tapaan kaikki kolme
          suomalaista puhelinoperaattoria, ja niiden vertailu on helppoa,
          joskaan kaikki mokkulat eivät ole samanlaisia. On esimerkiksi
          erilaisia mokkuloita liikkuvaan käyttöön ja yksittäisille tai monille
          eri päätelaitteille. Myös mokkulan käyttötapa vaihtelee, sillä mokkula
          voi toimia langattoman Wi-Fi:n tai USB-liitännän kautta, joskus myös
          verkkokaapelina tunnetun Ethernetin kautta. Jotta mokkula toimii,
          siihen tarvitsee ostaa siis erillinen laajakaistaliittymä, jonka
          SIM-kortti asennetaan mokkulaan täysin samalla tavalla kuin mihin
          tahansa älypuhelimeen. Nykyään tavallisten kuljetettavaksi
          tarkoitettujen mokkuloiden lisäksi on myynnissä myös erilaisia
          kiinteitä mokkularatkaisuja, jotka voivat olla hyvä vaihtoehto
          erilaisiin tarpeisiin.
        </TextArea>
        <TextArea>
          Talossa käytettävät kiinteät mokkulat käytännössä korvaavat kiinteän
          laajakaistaverkon silloin, kun omaan taloon ei ole saatavilla
          liittymää, tai toisaalta, jos et halua maksaa kahdesta eri netistä,
          eli oman kodin kiinteästä laajakaistasta ja erillisestä mobiilinetistä
          mökille. Ulkomokkulat, eli talon ulkopuolelle asennettava antenni ja
          siihen yhdistetty mokkula ovat hyvä ratkaisu silloin, kun etsit
          sopivaa laajakaistaratkaisua esimerkiksi kesämökille. Tällaisissa
          kiinteämmissä mokkularatkaisuissa on myös erillinen antenni, joka
          auttaa mokkulan käytössä katvealueella. Toisekseen ulkomokkula voi
          muutenkin luoda paremman yhteyden kuin sisälle asetettu antenni.
          Suomalaisista teleoperaattoreista Telian nettikaupassa mokkulat
          tunnetaan yksinkertaisesti nimellä reititin, ja kaikissa
          nettikaupoissa mokkuloita mainostetaan myös erikseen ostettavina.
        </TextArea>
        <Header4>Sisältääkö halvin nettiliittymä myös mokkulan?</Header4>
        <TextArea>
          Yleensä liittymäpaketissa mokkulan saa ikään kuin kaupan päälle, ja
          niiden hinnat erikseen ostettuna voivat vaikuttaa kalliilta.
          Esimerkiksi Huawein langaton 4G-reititinmalli maksaa Telian
          nettikaupassa erikseen ostettuna 120 euroa ilman liittymää. Samalla
          hinnalla voi käytännössä ostaa myös halvemman älypuhelimen, joka
          toimii reitittimenä hyvin ainakin muutamalle laitteelle. Myös DNA:lta
          voi ostaa mobiilireitittimen erikseen, jolloin esimerkiksi DNA
          Kotimokkulan hinta on kertamaksuna 185 euroa. Ulkoantennilla
          varustetulla kotimokkulalla on hintaa 299 euroa. Elisalla on
          teleoperaattoreista kaikista laajin valikoima erilaisia mokkuloita
          tunnetuimmilta kehittäjiltä, kuten Huaweilta, Netgearilta ja ZTE:ltä,
          ja niiden hinnat liikkuvat 100 euron ja 350 euron välillä
          kertahintaisena. Useimmiten mokkuloista tehdään 36 kuukauden sopimus.
        </TextArea>
        <Header3>
          Mobiililaajakaista – näin valitset oikean mobiililaajakaistan
        </Header3>
        <TextArea>
          Mobiililaajakaista on nykyään suomalaisten käytetyin ratkaisu olla
          yhteydessä verkkoon. Suomalaiset käyttävät järkyttävän määrän
          mobiilidataa kansalaista kohden, ja esimerkiksi Viestintäviraston
          tilastojen mukaan vuoden 2018 elokuun ja joulukuun välisenä aikana
          matkapuhelinverkon laajakaistaliittymissä, eli mobiililaajakaistalla,
          virtasi dataa 26 gigaa jokaista suomalaista kohti. Suomi onkin
          langattomien verkkojen levinneisyydessä yksi tilastojen kärjessä
          olevista valtioista. Mobiililaajakaista toimii Suomessa lähes
          kaikkialla, pois lukien katvealueet. Useita 3G- ja 4G-verkon
          tukiasemia löytyy nykyään myös syrjäseuduilta. Voikin olla siis hyvin
          vaikea päättää sitä, minkälainen mobiililaajakaistaliittymä
          kannattaisi hankkia. Mobiilinetti kannattaa valita oman tarpeen,
          toisin sanoen puhelimen tai muun laitteen käytön perusteella.
        </TextArea>
        <TextArea>
          Tärkeintä on pohtia sitä, mitä puhelimella oikeastaan tekee. Jos
          käytät puhelinta vain esimerkiksi Whatsapp-viestien lähettämiseen ja
          satunnaiseen nettiselailuun, tai esimerkiksi karttapalvelujen ja
          navigaation käyttöön, riittää sinulle ihan tavallinen perusnetti, joka
          on alimmillaan nykyään 100 megaa. Tällaisesta
          mobiililaajakaistaliittymästä tulee maksaa noin 20 euroa kuukaudessa
          kahden vuoden määräaikaisella sopimuksella puhelinoperaattorista
          riippumatta. Nykyään useimmilla puhelimen netin käyttö on yleisempää
          kuin itse puhelimen käyttö tavallisiin puheluihin tai
          tekstiviesteihin. Tämän ovat huomanneet myös teleoperaattorit, jotka
          tarjoavat mobiililaajakaistaliittymiä hyvin samanlaisilla
          hinnoittelumalleilla niille, jotka haluavat pikkunetin, mutta
          rajattomat puhelut ja viestit, ja niille, jotka haluavat nopeamman
          netin, mutta maksaa erikseen puheluista ja tekstiviesteistä.
        </TextArea>
        <Header4>Halvin nettiliittymä sopii moneen eri tarkoitukseen</Header4>
        <TextArea>
          Matkapuhelimiin ja mokkuloihin käytetyn mobiililaajakaistan lisäksi
          teleoperaattorit tarjoavat myös kapeampikaistaisia nettiliittymiä
          erilaisiin tarpeisiin sitä mukaa, kun erilaiset laitteet ovat tulleet
          sopiviksi liittää verkkoon.{" "}
          <b>
            ällaisia laitteita voi olla esimerkiksi koirapanta, valvontakamera
            tai riistakamera
          </b>
          , ja tulevaisuudessa vaikka oma jääkaappi. Tällaiset kevyen käytön
          mobiililaajakaistaliittymät voivat olla esimerkiksi yhden tai kahden
          megan suuruisia, ja niistä tulee maksaa 1-10 euroa kuukaudessa.
          Riippumatta siitä, minkä palveluntarjoajista valitset, luultavasti
          mobiilinettisi toimii missä asutkaan. Esimerkiksi DNA:n
          verkkokattavuus on yli 99 prosenttia siitä alueesta, jossa suomalaiset
          asuvat. Sama kattavuusosuus esimerkiksi Telialla on 96 prosenttia.
          Suurin osa kattavuusongelmista muodostuu yksittäisistä maanmuodoista
          ja raja-alueista.
        </TextArea>
        <TextArea>
          Jos mobiilinettisi ei kata asuinaluettasi, kyse on siis luultavasti
          vain huonosta tuurista. DNA tarjoaa mobiililaajakaistaa erilaisilla
          palvelupaketeilla, joissa nettien nopeudet vaihtelevat alle yhdestä
          megasta aina maksiminopeuteen, ja hinnat vaihtelevat noin kahdeksasta
          eurosta 50 euroon. Suomen edullisin mobiililaajakaista lienee DNA:n
          tytäryhtiönä nykyisin oleva Moi Mobiili, jonka 100 megan netti maksaa
          18 euroa kuukaudessa. Samalla megamäärällä esimerkiksi Telian
          mobiililaajakaistaliittymä maksaa vähintään 24,90 euroa kuukaudessa,
          ja Elisallakin hieman Moin liittymää enemmän. 100 tai 200 megabitin
          netti toimii aika hyvin myös vakavassa viihdekäytössä, eli jos katsot
          paljon liikkuvaa kuvaa puhelimella. Suurempimegaisia liittymiä voi
          harkita, jos puhelinta käyttää esimerkiksi paljon kansainvälisiin
          videopuheluihin.
        </TextArea>
        <Header4>Halvin nettiliittymä läppäriin ja tablettiin?</Header4>
        <TextArea>
          Nettiliittymän voi valita myös suoraan siihen kytkettävän laitteen
          perusteella, ja jos omassa älypuhelimessa on mobiililaajakaista
          käytössä, usein samanlainen liittymä toimii hyvin myös esimerkiksi
          tabletissa tai läppärissä. Toisaalta jos tabletti on hyvin vähässä
          käytössä, voidaan tähän ostaa erillinen todella edullinen netti, ja
          käyttää tablettia muuten esimerkiksi kotoa löytyvän kiinteän
          laajakaistaliittymän välityksellä WiFi-verkolla. Tällainen
          tablettikäyttö toimii hyvin esimerkiksi silloin, jos tablettia
          käytetään pääasiassa uutisten näköislehtien selaamiseen ja vaikkapa
          e-kirjojen lukemiseen. Tablettiin voi siis hankkia oman SIM-kortin ja
          käyttää sitä ikään kuin puhelimena, jolla on oma puhelinnumero, ja
          jossa toimivat kaikki samat sovellukset kuin älypuhelimessa.
        </TextArea>
        <TextArea>
          Toinen vaihtoehto on investoida esimerkiksi hieman suurempimegaiseen
          ja nopeampaan kiinteään laajakaistaliittymään, jonka kaistan leveys
          riittää yhteen lisälaitteeseen, jolloin tabletin lisäkäyttö ei aiheuta
          harmaita hiuksia muille netin käyttäjille. Toisaalta jotkut tabletit
          eivät tue omaa SIM-korttia, joten langattoman WiFi-verkon jakaminen
          joko mokkulalla tai älypuhelimella on pakollista. Jos tabletti on
          käytössä ainoastaan mökillä, mutta sen kanssa on käytössä useita
          muitakin päätelaitteita, kuten tietokoneita tai WiFi:ä käyttäviä
          älypuhelimia, kannattaa päivittää mökin mobiililaajakaista vastaamaan
          kasvaneen laitekäytön vaatimuksia. Jos taas käytät tablettia
          liikkeessä, kannattaa sille ostaa oma laajakaista. Muista nämä asiat,
          kun etsit kannettavaa nettiä tabletille tai läppärille:
          <ul>
            <li>
              Tabletteihin myytävät omat liittymät ovat vain hitaampia
              puhelinliittymiä
            </li>
            <li>
              Tablettien SIM-kortit ovat saman kokoisia kuin älypuhelimien
              SIM-kortit
            </li>
            <li>
              Kannettavaan tietokoneeseen myydään myös erillisiä nettitikkuja,
              jotka saa kytkettyä joko Ethernet-porttiin tai USB-porttiin
            </li>
            <li>
              Ethernet-kaapelin käyttö läppärillä on varmin tapa nopealle
              netille myös kiinteällä laajakaistalla
            </li>
          </ul>
        </TextArea>
        <TextArea>
          Tällaisia palveluja ovat esimerkiksi Telian Multisim-palvelu, jolla
          saa oman älypuhelimen liittymään rinnakkaisen SIM-kortin. Tällöin
          rinnakkaisen SIM-kortin käytöstä tulee maksaa kuukausittain kiinteä
          kuukausihinta, joka on viisi euroa. Jos haluat netin pelkkään
          tablettiin, Elisalta löytyy Mobiililaajakaista Tablet 4G, jonka 50
          megatavun kiinteän netin hinta on 2,90 euroa kuukaudessa, ja lisäksi
          0,99 euroa vuorokaudessa silloin, kun liittymää käytetään. DNA:lla
          vastaava palvelu on DNA Tablettinetti 4G, jonka hinta on yhtä suuri
          kuin Elisan liittymässä. Kilpailukykyinen hinta on myös Moi Mobiilin
          Kuuden euron simmillä, joka sisältää 4 gigatavua dataa per kuukausi,
          ja maksaa nimensä mukaisesti kuusi euroa kuussa.
        </TextArea>
        <TextArea>
          Kotikäytössä kannettavalle tietokoneelle kannattaa hankkia kiinteä
          laajakaistaliittymä, johon kannettava tietokone yhdistetään. Tällainen
          netti toimii moitteetta ja hyvin esimerkiksi kotitoimistolla tai
          opiskellessa. Toinen vaihtoehto on hankkia mobiililaajakaista ja
          mokkula, sillä läppärit harvoin sisältävät omaa reititintä. Joissain
          vanhemmissa läppärimalleissa on ollut sisäänrakennettu asema omalle
          SIM-kortille, jolloin läppärin nettiä voi käyttää sellaisenaan missä
          tahansa. Kolmas ja luultavasti yleisin vaihtoehto on hankkia
          kunnollinen mobiililaajakaista puhelimeen, ja jakaa netti puhelimesta
          Wi-Fi-verkkona myös läppärin käyttöön. Tällöin et ensinnäkään joudu
          hankkimaan erillistä nettiä läppärille, ja toiseksi älypuhelin on aina
          mukana, joten pääset myös läppärillä nettiin koska tahansa.
        </TextArea>
        <Header3>Mikä on halvin nettiliittymä?</Header3>
        <TextArea>
          Jos etsit halvinta nettiliittymää, kannattaa ottaa huomioon, että alan
          kilpailutilanteen vuoksi teleoperaattorien hinnat harvoin vaihtelevat
          hirveästi. Toki kaikilla eri teleoperaattoreilla on omat kampanjansa,
          jotka vaihtuvat useamman kerran vuodessa. Tämä voi olla hyvä keino
          vaihtaa liittymä halvempaan, vaihtamalla aina halvasta tarjouksesta
          toiseen. Muutamia euroja pystyy kuitenkin venyttämään myös ihan
          kiinteistäkin kuukausihinnoista eri liittymätyypeillä. Kannattaa lukea
          tarkasti pienet präntit, sillä kaikki operaattorit pyrkivät
          sisällyttämään jonkinnäköisiä sivukuluja asiakkaalle, jolloin he
          voivat painaa liittymien perushintaa helposti alaspäin. Voit
          esimerkiksi joutua maksamaan viisi euroa liittymätyypin vaihdosta.
          Myös avausmaksusta saatetaan veloittaa erikseen,
          mobiililaajakaistaliittymien tapauksessa kymmenen euroa uudesta
          SIM-kortista ja kymmenen euroa puhelimitse pyydetystä PUK-koodista.
        </TextArea>
        <TextArea>
          Ennen kun otat päätä pahkaa edullisimmalta vaikuttavan liittymän, lue
          siis liittymän käyttöehdot ja liittymäsopimus läpi, ettet joudu
          maksamaan turhista lisäpalveluista. Kun puhutaan kiinteän hinnan
          mobiililaajakaistaliittymistä, joihin kuuluu 100 megatavun rajaton
          netti, kaikista edullisin hinta kirjoitushetkellä on Moi Mobiilin 18
          euron rajaton netti. Seuraavaksi edullisin on Elisan kampanjahintainen
          Saunalahti Mobiililaajakaista 4G Super, jonka kampanjahinta on 18,40
          euroa. Tähän tulee päälle Elisan avausmaksu 4,90 euroa, ja 24
          kuukauden sopimusjakson jälkeen liittymän hinta on 29,80 euroa. DNA:n
          halvin 100 megatavun nettiliittymä maksaa 25,90 euroa, ja Telialla
          vastaava liittymä on 24,90 euroa kuukaudessa.
        </TextArea>
        <TextArea>
          Taloon tarkoitettujen kiinteiden laajakaistojen hinnat vaihtelevat
          täysin sen mukaan, missä netin käyttäjä asuu. Jos otetaan
          esimerkkiosoitteeksi Helsingin keskusta ja Punavuoren alueen
          Annankatu, DNA:lla pelkkä kiinteän laajakaistan nettipaketti maksaa
          29,90 eurosta 44,90 euroon kuukaudessa liittymän nopeudesta riippuen.
          Telian kiinteä laajakaistaliittymä samaan kiinteistöön maksaa
          vähintään 31,90 euroa, ja Elisalla edullisin perushinta on 32,90 euroa
          kuukaudessa. Nämä ovat kuitenkin vain netistä löytyviä hintoja, jotka
          voivat vaihdella jatkuvasti. Liittymäoperaattoreilla on erilliset
          myyntikäytännöt suoramyyntiin, kojumyyjille ja nettiin. Nettihinnoista
          voit saada hyvällä tuurilla vielä kymmeniä prosentteja pois erilaisina
          kampanjoina ja tarjouksina liittymästä, kytkytuotteista ja jopa
          vuodenajasta riippuen.
        </TextArea>
        <TextArea>
          On myös yksi koitettu ja toimivaksi todettu tapa löytää halvin
          liittymä itselle, joka vaatii vain hieman viitseliäisyyttä ja ehkä
          ripauksen röyhkeyttä. Kun olet ostamassa uutta laitetta ja kysyt
          liittymien hintoja, mene tarkoituksella toisen
          matkapuhelinoperaattorin liikkeeseen asioimaan. Kysy myyjältä, kuinka
          paljon uuteen laitteeseen maksaa tilata liittymä. Pyydä tarjous
          hinnasta, ja jos se on alhaisempi kuin nykyisen teleoperaattorisi
          tarjoama hinta, vaihda liittymä uudelle matkapuhelinoperaattorille.
          Saat aika varmasti seuraavan parin päivän aikana puhelun
          puhelinmyyjältä, joka kaupittelee sinulle edellistä liittymämerkkiäsi
          halvemmalla hinnalla. Tätä halpuutuskeinoa kannattaa kokeilla etenkin
          mobiililaajakaistaliittymille, mutta se voi hyvin toimia myös kiinteän
          laajakaistan tapauksessa.
        </TextArea>
        <Header2>Yhteenveto</Header2>
        <TextArea>
          Suomen operaattorikenttää hallitsevat kolme suurta
          matkapuhelinoperaattoria, ja kuten useissa muissakin maissa,
          liiketoiminta on hyvin keskittynyttä niiden operointiin. Tämä johtuu
          siitä, että matkapuhelinverkkojen rakentaminen on suhteellisen
          kallista puuhaa, johon ei ihan jokaisella pk-yrityksellä ole varaa.
          Suomeen ei myöskään tule uusia matkapuhelinalan yrityksiä, sillä
          täällä niillä ei ole juuri kehittymistä. Suomalaisten mobiilinetin
          käyttäminen on käytännössä saavuttanut jo saturaatiopisteensä, ja 99
          prosenttia suomalaisista asuu mobiilinetin käyttöalueella.
        </TextArea>
        <TextArea>
          Kun haluat löytää edullisimman nettiliittymän kotiin, kannattaa siis
          muistaa, että suurin osa hinnoista on hyvin samanlaisia eri
          operaattorien välillä. Hyvä syy vaihtaa muutamaa euroa edullisempaan
          voi olla esimerkiksi edellisen liittymäoperaattorin epätyydyttävä
          asiakaspalvelu, tai mahdollisten mielenkiintoisten lisäpalvelujen
          saanti mukaan omaan liittymäsopimukseen. Hintavertailun lisäksi siis
          tärkeää on myös se, mihin nettiä kotona käytetään. Halvimmillaan sadan
          megan nettiliittymän saa noin 30 eurolla kuukaudessa.
        </TextArea>
      </Container>
    </GlobalStyles>
  )
}

export const query = graphql`
  query {
    allInternetPlansJson {
      nodes {
        benefits
        id
        img
        internetSpeed
        internetType
        name
        openFee
        price
        url
      }
    }
  }
`

export default FrontPage
